import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { UserService } from '../services/user.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/switchMap';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(public userServ: UserService, public auth: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.userServ.token) {
            const headers = {};
            headers['Authorization'] = `Bearer ${this.userServ.token}`;
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request)
            .pipe(catchError((error => {
                console.log(error);
                console.log(request);
                // We don't want to refresh token for some requests like login or refresh token itself
                // So we verify url and we throw an error if it's the case
                if (request.url.includes('refresh') || request.url.includes('login')) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
                    if (request.url.includes('refresh')) {
                        this.auth.logOut();
                    }
                    return throwError(error);
                }


                // If error status is different than 401 we want to skip refresh token
                // So we check that and throw the error if it's the case
                if (error.status !== 401) {
                    return throwError(error);
                }

                // de aca para abajo es error y es de 401

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject
                        .filter(result => result !== null)
                        .take(1)
                        .switchMap(() => next.handle(this.addAuthenticationToken(request)));
                } else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    return this.auth.refreshToken()
                        .switchMap((token: any) => {
                            this.refreshTokenInProgress = false;
                            this.userServ.token = token.auth.token;
                            this.userServ.refreshToken = token.auth.refresh;

                            return next.handle(this.addAuthenticationToken(request));
                        });
                }

            })
            ));
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.userServ.token;
        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: this.userServ.token
            }
        });
    }
}
