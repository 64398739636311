import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {_throw} from 'rxjs-compat/observable/throw';

export interface NotebookInterface {
  id: number;
  title: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  user_id: any;
  files?: FileList;
  notebooks_targets: NotebookTargetInterface[];
}

export interface NotebookTargetInterface {
  id: number;
  notebook_id: number;
  school_id: number;
  course_id: number;
  level_id: number;
  role_id: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreateNotebookInterface {
  title: string;
  body: string;
  files?: FileList;
  schoolsId: number[];
  coursesId: number[];
  levelsId: number[];
  rolesId: number[];
  images?: any;
}

export interface HttpResponseInterface {
  ok: string;
  data?: any;
}

export interface HttpResponseAnyInterface {
  ok: string;
  data?: any;
}

export interface HttpDeleteNotebookInterface {
  ok: string;
  message: string;
}

@Injectable()
export class NotebookService {

  /**
   * URL BASE
   */
  dir = 'https://dev.k8s.braveup.cl';

  constructor(private http: HttpClient) {
  }

  /**
   * Obtiene las comunicaciones que se han enviado
   * @param courseId
   * @param roleId
   * @param schoolId
   * @param levelId
   */
  getNotebooks(schoolId?: number[], courseId?: number[]): Observable<NotebookInterface[]> {

    const url = Constants.server + '/notebooks/';
    const params = {};
    if (courseId) {params['courses_id'] = String(courseId.join()); }
    if (schoolId) {params['schools_id'] = String(schoolId.join()); }

    return this.http.get<HttpResponseInterface>(url, {params: params})
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => {
        return datos.data.notebooks;
      });
  }

  /**
   * Crea comunicacion
   * @param notebook
   */
  createNotebook(notebook: CreateNotebookInterface): Observable<NotebookInterface> {
    const url = Constants.server + '/notebooks/';

    const formData = new FormData();
    if (notebook.title) {formData.set('title', notebook.title); }
    if (notebook.body) {formData.append('body', notebook.body); }
    if (notebook.files) {
      for (let file = 0; file < notebook.files.length; file ++) {
        formData.append('file', notebook.files[file]);
      }
    }
    if (notebook.schoolsId) {formData.append('schools_id', notebook.schoolsId.join()); }
    if (notebook.coursesId) {formData.append('courses_id', notebook.coursesId.join()); }
    if (notebook.levelsId) {formData.append('levels_id', notebook.levelsId.join()); }
    if (notebook.rolesId) {formData.append('roles_id', notebook.rolesId.join()); }

    return  this.http.post<HttpResponseAnyInterface>(url, formData)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene una comunicacion especifica
   * @param notebookId
   */
  getNotebook(notebookId: number): Observable<NotebookInterface> {
    const url = Constants.server + '/notebooks/' + notebookId.toString();

    return this.http.get<HttpResponseAnyInterface>(url)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Edita comunicacion
   * @param notebook
   * @param notebookId
   */
  editNotebook(notebook: CreateNotebookInterface, notebookId: number): Observable<NotebookInterface> {
    const url = Constants.server + '/notebooks/' + notebookId.toString();
    const formData = new FormData();
    formData.set('title', notebook.title);
    formData.append('body', notebook.body);
    if (notebook.files) {
      for (let file = 0; file < notebook.files.length; file ++) {
        formData.append('file', notebook.files[file]);
      }
    }
    formData.append('schools_id', notebook.schoolsId.join());
    formData.append('courses_id', notebook.coursesId.join());
    formData.append('levels_id', notebook.levelsId.join());
    formData.append('roles_id', notebook.rolesId.join());

    return  this.http.patch<HttpResponseAnyInterface>(url, formData)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Borra comunicacion especifica
   * @param notebookId
   */
  deleteNotebook(notebookId: number): Observable<HttpDeleteNotebookInterface> {
    const url = Constants.server + '/notebooks/' + notebookId.toString();

    return  this.http.delete<HttpDeleteNotebookInterface>(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Borra archivo asociado a la comunicacion
   * @param notebookId
   */
  deleteFileNotebook(notebookId: number): Observable<HttpDeleteNotebookInterface> {
    const url = Constants.server + '/notebooks/file' + notebookId.toString();

    return  this.http.delete<HttpDeleteNotebookInterface>(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Borra los roles a los que llega la comunicacion
   * @param notebookId
   */
  deleteTargetNotebook(notebookId: number): Observable<HttpDeleteNotebookInterface> {
    const url = Constants.server + '/notebooks/target/' + notebookId.toString();
    return  this.http.delete<HttpDeleteNotebookInterface>(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Maneja errores que puedan producir las llamadas
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return _throw(
      'Something bad happened; please try again later.');
  }
}
