import { _throw } from 'rxjs/observable/throw';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpResponseAdmin, EstudianteInterface } from './superadmin.service';
import { Constants } from '../constants';

export interface BupInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    chat_activated: number;
    user_id: number;
    user_responsible_id: null;
    anonymous: number;
    feeling: string;
    description: string;
    solved: number;
    messages: MessageInterface[];
    user: UserInterface;
    responsible_user: UserInterface;
    school_groups: any;
}

export interface MessageInterface {
    id: number;
    user_id: number;
    text: string;
    createdAt: string;
    updatedAt: string;
}

export interface UserInterface {
    id: number;
    first_login: string;
    last_login: null;
    url_avatar: string;
    first_name: string;
    last_name: string;
    email: string;
    gender: string;
    password_token: null;
    expiration_token: null;
    is_admin: number;
    is_superadmin: number;
    admin_group_id: null;
    superadmin_group_id: null;
    superadmin_group?: null;
    admin_group?: null;
    school_groups?: any;
}

export interface HttpResponseBUP {
    ok: boolean;
    data?: any;
}

@Injectable()
export class CadenaBraveupService {
    // Quizas en constructor cargar datos del usuario
    constructor(private http: HttpClient) {}

    /**
     * Se obtienen los modo BUP de acuerdo a los filtros
     * @param name
     * @param schoolsId
     * @param coursesId
     * @param feelings
     */
    getBups(
        name?: string,
        schoolsId?: number[],
        coursesId?: number[],
        feelings?: string[]
    ): Observable<BupInterface[]> {
        const params = {};
        if (name) {
            params['name'] = String(name);
        }
        if (schoolsId) {
            params['schools_id'] = String(schoolsId.join());
        }
        if (coursesId) {
            params['courses_id'] = String(coursesId.join());
        }
        if (feelings) {
            params['feelings'] = String(feelings);
        }

        return this.http
            .get<HttpResponseBUP>(`${Constants.server}/bup-modes/`, {
                params: params
            })
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    getBupsProm(
        name?: string,
        schoolsId?: number[],
        coursesId?: number[],
        feelings?: string[]
    ): Promise<BupInterface[]> {
        const params = {};
        params['page_size'] = '500';

        if (name) {
            params['name'] = String(name);
        }
        if (schoolsId) {
            params['schools_id'] = String(schoolsId.join());
        }
        if (coursesId) {
            params['courses_id'] = String(coursesId.join());
        }
        if (feelings) {
            params['feelings'] = String(feelings);
        }
        return new Promise((resolve, reject) => {
            this.http
                .get<HttpResponseBUP>(`${Constants.server}/bup-modes/`, {
                    params: params
                })
                .pipe(catchError(this.handleError))
                .map(datos => datos.data)
                .subscribe(datos => resolve(datos));
        });
    }

    /**
     * Se obtiene modo BUP especifico
     * @param BUPid
     */
    getBup(BUPid: number): Observable<BupInterface> {
        const idString = String(BUPid);
        return this.http
            .get<HttpResponseBUP>(`${Constants.server}/bup-modes/${idString}`)
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Editar modo bup
     * @param BUPid
     * @param editorUser
     * @param solved
     */
    editBup(
        BUPid: number,
        editorUser: number,
        solved: number
    ): Observable<BupInterface> {
        const idString = String(BUPid);
        const url = `${Constants.server}/bup-modes/${idString}`;
        const body = {
            user_responsible_id: editorUser,
            solved: solved
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http
            .patch<HttpResponseBUP>(url, body, httpOptions)
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Crea mensaje en modo BUP
     * @param BUPid
     * @param comment
     */
    createMessageBUP(BUPid: number, comment: string): Observable<BupInterface> {
        const idString = String(BUPid);
        const url = `${Constants.server}/bup-modes/${idString}`;
        const body = { comment: comment };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http
            .post<HttpResponseBUP>(url, body, httpOptions)
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Activa chat de modo BUP
     * @param BUPid
     * @param activar
     */
    activateChat(BUPid: number, activar: boolean): Promise<any> {
        const url = `${Constants.server}/bup-modes/${BUPid}`;
        const body = {};
        if (activar) {
            body['chat_activated'] = 1;
        } else {
            body['chat_activated'] = 0;
        }
        return new Promise((resolve, reject) => {
            this.http
                .patch<HttpResponseAdmin>(url, body)
                .pipe(catchError(this.handleError))
                .map(datos => datos.data)
                .subscribe(data => {
                    resolve(data);
                });
        });
    }

    /**
     * Maneja errores que puedan producir las llamadas
     * @param error
     */
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return _throw('Something bad happened; please try again later.');
    }
}
