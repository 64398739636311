import {Injectable} from '@angular/core';

export interface Option {
  developer: boolean;
  chat: boolean;
  mainLayout: string;
  boxLayout: boolean;
  fixedSidebar: boolean;
  fixedHeader: boolean;
  headerColor: string;
  activeLinkColor: string;
  menuCaptionColor: string;
  colorIcon: string;
}

const CUSTOM =  {
  developer: false,
  chat: false,
  mainLayout: 'light',
  boxLayout: true,
  fixedSidebar: true,
  fixedHeader: true,
  headerColor: 'themelight1',
  activeLinkColor: '',
  colorIcon: 'st1',
  menuCaptionColor: '',
};

@Injectable()
export class Layout  {
  getAll(): Option {
    return CUSTOM;
  }
}
