import { PipesModule } from './pipes.modules';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';

// services
import {AuthenticationService} from './services/authentication.service';
import {CadenaBraveupService} from './services/cadena-braveup.service';
import {SurveyService} from './services/survey.service';
import {SuperadminService} from './services/superadmin.service';
import {NotebookService} from './services/notebook.service';
// guards
import {MainGuardGuard} from './guards/main-guard.guard';
import {EceGuard} from './guards/ece.guard';
import {SuperAdminGuard} from './guards/super-admin.guard';
// interfaces
import {Layout} from './interfaces/layout';
import {MenuItems} from './interfaces/menu-items';
import {User} from './interfaces/user';
import {CommonData} from './interfaces/common-data';
// translate
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
// notifications
import {ToastyModule} from 'ng2-toasty';
// AoT requires an exported function for factories
// videos
import { EmbedVideo } from 'ngx-embed-video';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// multiselect
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// Interceptor
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {UserService} from './services/user.service';

import * as Sentry from '@sentry/browser';
import { MomentPipe } from './moment.pipe';

Sentry.init({
  dsn: 'https://2db6ab501560485694b88cd1c7e2980d@sentry.io/1426253'
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error(error);
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    PipesModule,
    ToastyModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    MenuItems,
    Layout,
    User,
    CommonData,
    CadenaBraveupService,
    AuthenticationService,
    SurveyService,
    SuperadminService,
    UserService,
    NotebookService,
    MainGuardGuard,
    SuperAdminGuard,
    EceGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);
