import {Injectable} from '@angular/core';
import {EstudianteInterface} from '../services/superadmin.service';

export interface Data {
  months: any;
}

export interface Filters {
  category: any;
  categorySetting: any;
  schools: any;
  schoolsSetting: any;
  grades: any;
  gradesSetting: any;
  recivers: any;
  reciversSetting: any;
  levels: any;
  levelsSetting: any;
  users: any;
  usersSetting: any;
}

export interface Tags {
  tags: any;
  tagsSetting: any;
}

const MOONTHS: { months: [string, string, string, string, string, string, string, string, string, string, string, string] } = {
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
};
const FILTER: Filters = {
  category: [
    { 'id': 1, 'itemName': 'Información general' },
    { 'id': 3, 'itemName': 'Información Alumnos' }
  ],
  categorySetting: {
    singleSelection: false,
    text: 'Categorías',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    noDataLabel: 'No hay información disponible',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    maxHeight: 300
  },
  schools: [
  ],
  schoolsSetting: {
  singleSelection: false,
  text: 'Colegio',
  selectAllText: 'Seleccionar Todo',
  unSelectAllText: 'Quitar seleccion',
  noDataLabel: 'No hay información disponible',
  filterSelectAllText: 'Todos estan seleccionados',
  searchPlaceholderText: 'Buscar',
  enableSearchFilter: true,
  badgeShowLimit: 1,
    maxHeight: 300
  },
  grades: [
  ],
  gradesSetting: {
    singleSelection: false,
    text: 'Cursos',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Quitar seleccion',
    noDataLabel: 'No hay información disponible',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    groupBy: 'category',
      disabled : false,
      selectGroup: true,
    maxHeight: 300
  },
  recivers: [
    ],
  reciversSetting: {
    singleSelection: false,
    text: 'Enviar a',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Quitar seleccion',
    noDataLabel: 'No hay información disponible',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    maxHeight: 300
  },
  levels: [
  ],
  levelsSetting: {
    singleSelection: false,
    text: 'Niveles',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Quitar seleccion',
    noDataLabel: 'No hay información disponible',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    maxHeight: 300
  },
  users: [],
  usersSetting: {
    singleSelection: true,
    text: 'Estudiantes',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Quitar selección',
    noDataLabel: 'No hay usuarios disponibles',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    maxHeight: 300
  }
};
const TAGS = {
  tags: [
    { 'id': 1, 'itemName': 'Ciberbullying' },
    { 'id': 2, 'itemName': 'Sexting' },
    { 'id': 3, 'itemName': 'Grooming'},
    { 'id': 4, 'itemName': 'Violencia Física'},
    { 'id': 5, 'itemName': 'Violencia Psicológica'},
    { 'id': 6, 'itemName': 'Otro'}
    ],
  tagsSetting: {
    singleSelection: false,
    text: 'Etiquetas',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar Todo',
    noDataLabel: 'No hay información disponible',
    filterSelectAllText: 'Todos estan seleccionados',
    searchPlaceholderText: 'Buscar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    maxHeight: 300
  }
};

@Injectable()
export class CommonData  {
  getAll(): Data {
    return MOONTHS;
  }
  getFilters(): Filters {
    return FILTER;
  }
  getTags(): Tags {
    return TAGS;
  }
  setGrades(grades) {
    FILTER.grades = grades.map(grade => {
      if (grade.name !== 'Colegio Brave UP' && grade.level !== null) {
        return {
          id: grade.id,
          itemName: grade.name,
          category: grade.level.name
        };
      } else {
        return null;
      }
    })
      .filter(grade => grade !== null);
  }

  setSchools(schools) {
    FILTER.schools = schools.map(school => {
      return {
        id: school.id,
        itemName: school.name
      };
    });
  }
  setRoles(roles) {
    FILTER.recivers = roles.map(rol => {
      return {
        id: rol.id,
        itemName: rol.name
      };
    });
  }
  setLevels(levels) {
    FILTER.levels = levels.map(level => {
      return {
        id: level.id,
        itemName: level.name
      };
    });
  }

  setUsers(estudiantes: EstudianteInterface[]) {
    FILTER.users = estudiantes;
  }

  setUserSetNoDataLabel(message) {
    FILTER.usersSetting.noDataLabel = message;
  }
}
