import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {_throw} from 'rxjs-compat/observable/throw';

export interface SurveyInterface {
  id: number;
  name: string;
  link: string;
  school_id: null;
  course_id: null;
  role_id: null;
  createdAt: string;
  updatedAt: any;
  expiracy_date: any;
  forms_targets: TargetInterface[];
}

export interface TargetInterface {
  id: number;
  form_id: number;
  school_id: number;
  course_id: null;
  level_id: number;
  role_id: number;
  createdAt: string;
  updatedAt: string;
}

export interface HttpResponseSurvey {
  ok: boolean;
  forms?: any;
}

export interface HttpResponseCreateSurvey {
  ok: boolean;
  data?: any;
}

@Injectable()
export class SurveyService {

  /**
   * URL BASE
   */
  dir = 'https://dev.k8s.braveup.cl';

  constructor(private http: HttpClient) {
  }


  /**
   * Obtiene encuestas enviadas
   * @param courseId
   * @param schoolsId
   * @param levelsId
   * @param rolesId
   */
  getForms(courseId?: number[], schoolsId?: number[], levelsId?: number[], rolesId?: number[]): Observable<SurveyInterface[]> {
    const params = {};
    const url = Constants.server + '/forms/';

    if (rolesId) {params['roles_id'] = String(rolesId); }
    if (courseId) {params['course_id'] = String(courseId.join()); }
    if (levelsId) {params['level_id'] = String(levelsId.join()); }
    if (schoolsId) {params['school_id'] = String(schoolsId.join()); }

    return this.http.get<HttpResponseSurvey>(url, {params: params})
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => {
        return datos.forms; });
  }

  /**
   * Crea encuesta
   * @param expiracy
   * @param name
   * @param link
   * @param schoolsId
   * @param coursesId
   * @param rolesId
   * @param levelsId
   */
  createForm(expiracy: number, name: string, link: string, schoolsId?: number[],
             coursesId?: number[], rolesId?: number[], levelsId?: number[]): Observable<SurveyInterface> {
    const url = Constants.server + '/forms/';
    const body = {};
    body['name'] = name;
    body['link'] = link;

    if (schoolsId) {body['schools_id'] = schoolsId.join(); }
    if (coursesId) {body['courses_id'] = coursesId.join(); }
    if (rolesId) {body['roles_id'] = rolesId.join(); }
    if (levelsId) {body['levels_id'] = levelsId.join(); }
    if (expiracy) {body['expiracy'] = expiracy; }

    const httpOptions = {headers: new HttpHeaders({'Content-Type':  'application/json' }) };

    return this.http.post<HttpResponseCreateSurvey>(url, body)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene encuesta especifica
   * @param FormId
   */
  getForm(FormId: number): Observable<SurveyInterface> {
    const url = Constants.server + '/forms/' + String(FormId);
    return this.http.get<HttpResponseCreateSurvey>(url)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Edita encuesta
   * @param FormId
   * @param name
   * @param link
   * @param schoolId
   * @param courseId
   * @param roleId
   */
  editForm(FormId: number, expiracy: number, name: string, link: string,
           schoolId: number, courseId: number, roleId: number): Observable<SurveyInterface>  {
    const url = Constants.server + '/forms/' + String(FormId);
    const body = {};
    body['name'] = name;
    body['link'] = link;
    if (schoolId) {body['school_id'] = String(schoolId); }
    if (courseId) {body['course_id'] = String(courseId); }
    if (roleId) {body['role_id'] = String(roleId); }
    if (expiracy) {body['expiracy'] = expiracy; }
    const httpOptions = {headers: new HttpHeaders({'Content-Type':  'application/json' }) };
    return this.http.patch<HttpResponseCreateSurvey>(url, body, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Maneja errores que puedan producir las llamadas
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return _throw(
      'Something bad happened; please try again later.');
  }
}
