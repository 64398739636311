import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import { MenuItems } from '../interfaces/menu-items';
import { Layout } from '../interfaces/layout';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { UserInfoInterface, UserService } from './user.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { _throw } from 'rxjs-compat/observable/throw';
import * as Cookies from 'es-cookie';

@Injectable()
export class AuthenticationService {
    /**
     * Configuracion de Menu de intranet
     */
    menuItemsOptionBraveUp = [
        {
            label: 'Administrador',
            main: [
                {
                    state: 'admin-dashboard',
                    short_label: 'D',
                    name: 'Escritorio',
                    type: 'link',
                    ece: 'true',
                    icon: 'ti-desktop'
                },
                {
                    state: 'admin-schools',
                    short_label: 'A',
                    name: 'Administrar',
                    type: 'sub',
                    icon: 'ti-panel',
                    children: [
                        {
                            state: 'list-schools',
                            name: 'Lista colegios'
                        }
                    ]
                }
            ]
        },
        {
            label: 'Navegacion',
            main: [
                {
                    state: 'dashboard',
                    short_label: 'D',
                    name: 'Escritorio',
                    type: 'link',
                    icon: 'ti-desktop'
                },
                {
                    state: 'brave-up',
                    short_label: 'BU',
                    name: 'Brave Up',
                    type: 'sub',
                    icon: ' ti-eye',
                    children: [
                        {
                            state: 'recived',
                            name: 'Mensajes'
                        },
                        {
                            state: 'logbook',
                            name: 'Bitácoras'
                        }
                    ]
                },
                {
                    state: 'notifications',
                    short_label: 'N',
                    name: 'Libreta',
                    type: 'sub',
                    icon: 'ti-book',
                    children: [
                        {
                            state: 'add-notifications',
                            name: 'Enviar Comunicación'
                        },
                        {
                            state: 'list-notifications',
                            name: 'Lista comunicaciones'
                        }
                    ]
                },
                {
                    state: 'news',
                    short_label: 'N',
                    name: 'Muro',
                    type: 'sub',
                    icon: 'ti-pin2',
                    children: [
                        {
                            state: 'add-news',
                            name: 'Añadir Publicación'
                        },
                        {
                            state: 'list-news',
                            name: 'Ver Publicaciones'
                        },
                        {
                            state: 'approve-news',
                            name: 'Aprobar Publicaciones'
                        }
                    ]
                },
                {
                    state: 'surveys',
                    short_label: 'E',
                    name: 'Encuestas',
                    type: 'sub',
                    icon: 'ti-write',
                    children: [
                        {
                            state: 'add-survey',
                            name: 'Añadir Encuestas'
                        },
                        {
                            state: 'list-survey',
                            name: 'Ver Encuestas'
                        }
                    ]
                }
            ]
        },
        {
            label: 'Docente',
            main: [
                {
                    state: 'dashboard',
                    short_label: 'D',
                    name: 'Escritorio',
                    type: 'link',
                    icon: 'ti-desktop'
                },
                {
                    state: 'notifications',
                    short_label: 'N',
                    name: 'Libreta',
                    type: 'sub',
                    icon: 'ti-book',
                    children: [
                        {
                            state: 'add-notifications',
                            name: 'Enviar Comunicación'
                        },
                        {
                            state: 'list-notifications',
                            name: 'Lista comunicaciones'
                        }
                    ]
                },
                {
                    state: 'news',
                    short_label: 'N',
                    name: 'Muro',
                    type: 'sub',
                    icon: 'ti-pin2',
                    children: [
                        {
                            state: 'add-news',
                            name: 'Nueva Publicación'
                        },
                        {
                            state: 'list-news',
                            name: 'Ver Publicaciones'
                        },
                        {
                            state: 'approve-news',
                            name: 'Aprobar Publicaciones'
                        }
                    ]
                },
                {
                    state: 'surveys',
                    short_label: 'E',
                    name: 'Encuestas',
                    type: 'sub',
                    icon: 'ti-write',
                    children: [
                        {
                            state: 'add-survey',
                            name: 'Nueva Encuestas'
                        },
                        {
                            state: 'list-survey',
                            name: 'Ver Encuestas'
                        }
                    ]
                }
            ]
        }
    ];

    constructor(
        public menuItems: MenuItems,
        public layout: Layout,
        public user: User,
        public router: Router,
        private http: HttpClient,
        private userServ: UserService
    ) {}

    // Login Backend
    loginOauth(email: string, password: string) {
        return this.http.post<any>(`${Constants.server}/login`, {
            email: email,
            password: password
        });
    }

    /**
     * Limpia el usuario que esta logeado para hacer el logout
     */
    logOut() {
        this.userServ.refreshToken = null;
        this.userServ.token = null;
        this.userServ.userInfo = {} as UserInfoInterface;
        this.user.ResetearUser();
        Cookies.remove('remember');
        Cookies.remove('userData');
        this.user.Define({ active: false });
        this.router.navigate(['/authentication/login']);
    }

    /**
     * set up menu items, es importante que esto sea realizado mietras se realiza el login, y antes de rutear a la pagina de inicio
     * @param user
     * @param admin
     * @param superAdmin
     * @param ece
     * @param docente
     */
    setUp(user, admin, superAdmin, ece, docente) {
        this.userServ.setUser(user, admin, superAdmin, ece, docente);
        this.menuItems.InitMenu();
        const datos = this.layout.getAll();
        const infoUser = this.userServ.userInfo;
        this.menuItemsOptionBraveUp.map(item => {
            if (infoUser.profile === 'superAdmin') {
                if (
                    item.label === 'Navegacion' ||
                    item.label === 'Administrador'
                ) {
                    this.menuItems.addItems(item);
                }
            }
            if (infoUser.profile === 'admin') {
                if (
                    item.label === 'Navegacion' ||
                    item.label === 'Administrador'
                ) {
                    this.menuItems.addItems(item);
                }
            }
            if (infoUser.profile === 'ece') {
                if (
                    item.label === 'Navegacion' ||
                    item.label === 'Administrador'
                ) {
                    this.menuItems.addItems(item);
                }
            }
            if (infoUser.profile === 'docente') {
                if (item.label === 'Docente') {
                    this.menuItems.addItems(item);
                }
            }
        });
        const informaciondelusuario = this.userServ.userInfo;
        this.user.DefinirInfoUsuario(informaciondelusuario);
    }

    /**
     * enruta a la pagina correspondiente
     */
    routering() {
        this.router.navigate(['/']);
    }

    /**
     * Promesa para enviar forget password
     * @param userEmail
     */
    forgetPassword(userEmail: string): Promise<any> {
        const url = `${Constants.server}/forgot-password/`;
        const body = {
            email: userEmail
        };
        return new Promise((resolve, reject) => {
            this.http
                .post<any>(url, body)
                .pipe(catchError(this.handleError))
                .map(datos => datos.data)
                .subscribe(data => {
                    resolve(data);
                });
        });
    }

    /**
     * Refresca el token de autenticacion
     */
    refreshToken() {
        const url = `${Constants.server}/refresh/`;
        const body = {
            refresh: this.userServ.refreshToken
        };
        return this.http.post(url, body);
    }

    /**
     * Maneja errores que se genera al refrescar token
     * @param error
     */
    private handleErrorRefresh(error: HttpErrorResponse) {
        console.error(
            `Backend returned code ${error.status}, ` +
                `body was: ${error.error}` +
                `Cannot Refresh Token`
        );
        this.logOut();
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return _throw('Something bad happened; please try again later.');
    }
}
