import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { User} from '../interfaces/user';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(public user: User, public router: Router, private userServ: UserService) {
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const infoUser = this.userServ.userInfo;
    if (this.user.getAll().active) {
      if ( infoUser.profile === 'superAdmin') {
        return true;
      }
      if ( ['admin', 'ece', 'docente'].includes(infoUser.profile)) {
        this.router.navigate(['/dashboard']);
        return false;
      }
    } else {
      this.router.navigate(['/authentication/login']);
    }
  }
}
