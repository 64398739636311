import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentFormat'
})
export class MomentPipe implements PipeTransform {

    transform(value: string, format: string) {
        return moment(value).format(format);
    }
}
