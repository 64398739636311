import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {_throw} from 'rxjs-compat/observable/throw';

export interface SchoolInterface {
  id: number;
  name: string;
  address: string;
  extended_gender_option: number;
  createdAt?: string;
  updatedAt?: string;
  braveup_mode: number;
  notifications: number;
  forms: number;
  binnacle: number;
  news: number;
  courses?: CourseInterface[];
  ece?: any;
  avatar_url?: string;
  email?: string;
  phone?: string;
}

export interface CourseInterface {
  id: number;
  name: string;
  level_id: number;
  nivel: number;
  year: number;
  school_id?: number;
  letter: string;
  createdAt: string;
  updatedAt: string;
  level?: LevelInterface;
}

export interface EstudianteInterface {
  id: number;
  itemName: string;
}

export interface LevelInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CreateSchoolInterface {
  name: string;
  address: string;
  phone: string;
  email: string;
  extended_gender_option?: number;
  permissions: {
    news: number;
    braveup_mode: number;
    notifications: number;
    forms: number;
    binnacles: number;
  };
}

export interface RolInterface {
  id: number;
  name: string;
  description: string;
  is_teacher: number;
  is_student: number;
  is_parent: number;
  createdAt: null;
  updatedAt: null;
  permission_id: number;
  multiple_courses: number;
}

export interface HttpResponseAdmin {
  ok: boolean;
  data?: any;
}

export interface CheckCredentialsInterface {
  id: number;
  name: string;
  key: string;
  role_id: number;
  course_id: number;
  school_id: null;
  createdAt: null;
  updatedAt: null;
  course: {
    id: 7;
    name: string;
    level_id: number;
    nivel: number;
    year: number;
    school_id: number;
    letter: string;
    createdAt: null;
    updatedAt: string;
  };
  school: null;
  role: any;
}

@Injectable()

export class SuperadminService {

  /**
   * URL BASE
   */
  dir = 'https://dev.k8s.braveup.cl';

  constructor(private http: HttpClient) {
  }

  /**
   * Obtiene los colegios disponibles
   */
  getSchools(): Observable<SchoolInterface[]> {
    const url = Constants.server + '/schools/';
    return this.http.get<HttpResponseAdmin>(url)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene los colegios como promesa
   */
  getSchoolsPromise(): Promise<any> {
    const url = Constants.server + '/schools/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(datos => {
          resolve(datos.data);
        });
    });
  }

  /**
   * Crea un colegio
   * @param schoolData
   */
  createSchool(schoolData: CreateSchoolInterface): Observable<SchoolInterface> {
    const url = Constants.server + '/schools/';
    const body = schoolData;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.post<HttpResponseAdmin>(
      url, body, httpOptions
    )
      .pipe(
      catchError(this.handleError)
    )
      .map(datos => datos.data);
  }

  /**
   * Edita el colegio
   * @param schoolData
   * @param schoolId
   */
  editSchool(schoolData: CreateSchoolInterface, schoolId: number): Observable<SchoolInterface> {
    const url = Constants.server + '/schools/' + schoolId.toString();
    const body = schoolData;

    return this.http.patch<HttpResponseAdmin>(
      url, body
    )
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene colegio especifico
   * @param schoolId
   */
  getSpecificSchool(schoolId: number): Observable<SchoolInterface> {
    const url = Constants.server + '/schools/' + String(schoolId);
    return this.http.get<HttpResponseAdmin>(
      url
    )
      .pipe(
      catchError(this.handleError)
    )
      .map(datos => datos.data);
  }

  /**
   * Obtiene cursos de un colegio
   * @param schoolId
   */
  getSchoolGrades(schoolId: number): Promise<CourseInterface[]> {
    const url = Constants.server + '/schools/' + String(schoolId) + '/courses';

    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .map(datos => datos.data)
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene todos los estudiantes de todos los cursos de un colegio
   * @param schoolId
   * @param coursesId
   */
  getCountStudents(schoolId: number, coursesId: number[]): Promise<any> {
    let promesaPorCurso = coursesId.map(courseId => {
      return this.getStudents([schoolId], [courseId]);
    });
    return Promise.all(promesaPorCurso);
  }

  /**
   * Obtiene estudiantes de un curso de un colegio
   * @param schoolId
   * @param coursesId
   */
  getStudents(schoolId: number[], coursesId?: number[]): Promise<EstudianteInterface[]> {
    const url = Constants.server + '/users/';
    const params = {};
    if (schoolId) {params['schools_id'] = String(schoolId.join()); }
    if (coursesId) {params['courses_id'] = String(coursesId.join()); }
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url, {params: params}
      )
        .pipe(
          catchError(this.handleError)
        )
        .map(datos => {
          return datos.data.map(user => {
              if (user.school_groups[0].role.is_student === 1) {
                return {
                  id: user.id,
                  itemName: user.first_name + ' ' + user.last_name
                };
              }
            }); })
        .subscribe(data => {
          resolve(data);
        },
            error1 => {
          reject(error1);
            });
    });
  }

  /**
   * Obtiene todos los usuarios de un colegio
   * @param schoolId
   * @param coursesId
   */
  getUsers(schoolId: number[], coursesId?: number[]): Promise<EstudianteInterface[]> {
    const url = Constants.server + '/users/';
    const params = {};
    if (schoolId) {params['schools_id'] = String(schoolId.join()); }
    if (coursesId) {params['courses_id'] = String(coursesId.join()); }
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url, {params: params}
      )
        .pipe(
          catchError(this.handleError)
        )
        .map(datos => {
          return datos.data.map(user => {
              return {
                id: user.id,
                itemName: user.first_name + ' ' + user.last_name
              };
          });
        })
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Crea un curso en un colegio
   * @param schoolId
   * @param name
   * @param levelId
   */
  createCourse(schoolId: number, name: string, levelId: number): Observable<SchoolInterface> {
    const url = Constants.server + '/schools/' + String(schoolId);
    const body = {
      name: name,
      level_id: levelId
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post<HttpResponseAdmin>(
      url, body, httpOptions
    )
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene los niveles disponibles
   * No necesita parametro porque es fijo
   */
  getLevels(): Observable<LevelInterface[]> {
    const url = Constants.server + '/levels/';
    return this.http.get<HttpResponseAdmin>(url)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data);
  }

  /**
   * Obtiene los roles disponibles
   * No necesita parametro porque es fijo
   */
  getRoles(): Promise<RolInterface[]> {
    const url = Constants.server + '/users/roles/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .map(datos => datos.data)
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene credenciales para generar código QR
   * @param schoolId
   */
  checkCredentials(schoolId: number): Promise<CheckCredentialsInterface[]> {
    const url = Constants.server + '/schools/' + schoolId.toString() + '/credentials';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .map(datos => datos.data)
        .subscribe(data => {
          resolve(data.credentials);
        });
    });
  }

  /**
   * Obtiene KPI de noticias
   */
  getNewsKpi(): Promise<any> {
    const url = Constants.server + '/news/kpi/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene KPI de bitacoras
   */
  getBinnaclesKpi(): Promise<any> {
    const url = Constants.server + '/binnacles/kpi/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene KPI de modos BUP
   */
  getBupKpi(): Promise<any> {
    const url = Constants.server + '/bup-modes/kpi/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene KPI de encuestas
   */
  getFormsKpi(): Promise<any> {
    const url = Constants.server + '/forms/kpi/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Obtiene KPI de comunicaciones
   */
  getNotebooksKpi(): Promise<any> {
    const url = Constants.server + '/notebooks/kpi/';
    return new Promise((resolve, reject) => {
      this.http.get<HttpResponseAdmin>(
        url
      )
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * Maneja errores que puedan producir las llamadas
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return _throw(
      'Something bad happened; please try again later.');
  }

}
