import { Injectable } from '@angular/core';
import {
    CourseInterface,
    LevelInterface,
    RolInterface,
    SchoolInterface,
    SuperadminService
} from './superadmin.service';
import { CommonData } from '../interfaces/common-data';

export interface UserInfoInterface {
    id: number;
    first_login: string;
    url_avatar: string;
    first_name: string;
    last_name: string;
    email: string;
    gender: string;
    password_token: string;
    expiration_token: string;
    profile: string;
    admin_group_id: any;
    superadmin_group_id: any;
}

@Injectable()
export class UserService {
    /**
     * URL BASE
     */
    // dir = 'https://dev.k8s.braveup.cl';

    userInfo = {} as UserInfoInterface;

    token: any = null;
    refreshToken: any = null;

    // SCHOOL LISTO
    userSchools: SchoolInterface[] = [] as SchoolInterface[];
    schools: SchoolInterface[] = [] as SchoolInterface[];
    // LEVELS LISTO
    levels: any;
    // COURSES LISTO
    courses: CourseInterface[] = [] as CourseInterface[];
    // ROLES LISTO
    roles: RolInterface[] = [] as RolInterface[];

    constructor(
        private adminServ: SuperadminService,
        private commonData: CommonData
    ) {}

    /**
     * Setea el tipo de usuario que inicio sesion
     * @param user
     * @param admin
     * @param superAdmin
     * @param ece
     * @param docente
     */
    setUser(user, admin, superAdmin, ece, docente) {
        console.log(this);
        if (ece === 1) {
            this.setEceInfo(user);
        } else if (docente === 1) {
            this.setTeacherInfo(user);
        } else if (superAdmin === 1) {
            this.setSuperAdminInfo(user);
        } else if (admin === 1) {
            this.setAdminInfo(user);
        }
    }

    /**
     * Setea informacion de ECE
     * @param user
     */
    setEceInfo(user) {
        this.setEceUserInfo(user);
        this.setSchoolConfig();
    }

    /**
     * Setea informacion de Profesor
     * @param user
     */
    setTeacherInfo(user) {
        this.setTeacherUserInfo(user);
        this.setSchoolConfig();
    }

    /**
     * Setea informacion de usuario super administrador
     * @param user
     */
    setSuperAdminInfo(user: any) {
        this.setSuperAdminUserInfo(user);
        this.setSchoolConfig();
    }

    /**
     * Setea informacion de ECE
     * @param user
     */
    setAdminInfo(user) {
        this.setEceInfo(user);
    }

    /**
     * Obtiene los niveles, los colegios y los roles disponibles para el usuario como variable global
     */
    setSchoolConfig() {
        this.getLevels();
        this.getSchools();
        this.getRoles();
    }

    /**
     * Setea informacion de usuario de administrador
     * @param user
     */
    setSuperAdminUserInfo(user) {
        const info = user.data.user;
        this.userInfo.id = info.id;
        this.userInfo.first_login = info.first_login;
        this.userInfo.url_avatar = '';
        this.userInfo.first_name = info.first_name;
        this.userInfo.last_name = info.last_name;
        this.userInfo.email = info.email;
        this.userInfo.gender = info.gender;
        this.userInfo.password_token = info.password_token;
        this.userInfo.expiration_token = info.expiration_token;
        this.userInfo.profile = 'superAdmin';
        this.userInfo.admin_group_id = info.admin_group_id;
        this.userInfo.superadmin_group_id = info.superadmin_group_id;
    }

    /**
     * Setea informacion de usuario profesor
     * @param user
     */
    setTeacherUserInfo(user) {
        const info = user.data.user;
        this.userInfo.id = info.id;
        this.userInfo.first_login = info.first_login;
        this.userInfo.url_avatar = info.url_avatar;
        this.userInfo.first_name = info.first_name;
        this.userInfo.last_name = info.last_name;
        this.userInfo.email = info.email;
        this.userInfo.gender = info.gender;
        this.userInfo.password_token = info.password_token;
        this.userInfo.expiration_token = info.expiration_token;
        this.userInfo.profile = 'docente';
        this.userInfo.admin_group_id = info.admin_group_id;
        this.userInfo.superadmin_group_id = info.superadmin_group_id;

        this.userSchools = info.school_groups.map(schoolGroup => {});
    }

    /**
     * Setea informacion de usuario ECE
     * @param user
     */
    setEceUserInfo(user) {
        const info = user.data.user;
        this.userInfo.id = info.id;
        this.userInfo.first_login = info.first_login;
        this.userInfo.url_avatar = info.url_avatar;
        this.userInfo.first_name = info.first_name;
        this.userInfo.last_name = info.last_name;
        this.userInfo.email = info.email;
        this.userInfo.gender = info.gender;
        this.userInfo.password_token = info.password_token;
        this.userInfo.expiration_token = info.expiration_token;
        if (info.is_admin === 1) {
            this.userInfo.profile = 'admin';
        } else {
            this.userInfo.profile = 'ece';
        }
        this.userInfo.admin_group_id = info.admin_group_id;
        this.userInfo.superadmin_group_id = info.superadmin_group_id;

        if (info.is_admin === 1) {
            this.schools = info.admin_group.schools.map(school => {
                return this.returnSchools(school);
            });
        } else {
            console.log('schools', this.schools);
            this.schools = info.school_groups.map(schoolGroup => {
                const school = schoolGroup.school || schoolGroup.course.school;
                console.log('school', school);
                return this.returnSchools(school);
            });
        }
        this.commonData.setSchools(this.schools);
        this.getCourses(this.schools[0].id);
    }

    /**
     * Funcion auxiliar para informacion del colegio
     * @param school
     */
    returnSchools(school) {
        return {
            id: school.id,
            name: school.name,
            address: school.address,
            extended_gender_option: school.extended_gender_option,
            braveup_mode: school.braveup_mode,
            notifications: school.notification,
            forms: school.forms,
            binnacle: school.binnacle,
            news: school.news,
            avatar_url: school.avatar_url,
            email: school.email,
            phone: school.phone
        };
    }

    /**
     * Funcion auxiliar para guardar los niveles
     */
    getLevels() {
        this.adminServ.getLevels().subscribe(levels => {
            this.levels = levels;
            this.commonData.setLevels(this.levels);
        });
    }

    /**
     * Funcion auxiliar para guardar los roles
     */
    getRoles() {
        this.adminServ.getRoles().then(roles => {
            this.roles = roles;
            this.commonData.setRoles(this.roles);
        });
    }

    /**
     * Funcion auxiliar para guardar colegios
     */
    getSchools() {
        this.adminServ.getSchools().subscribe(schools => {
            this.schools = schools;
            this.commonData.setSchools(schools);
        });
    }

    /**
     * Funcion auxiliar para obtener colegio como promesa
     */
    getSchoolsPromise() {
        return this.adminServ.getSchoolsPromise().then(schools => {
            this.schools = schools;
            this.commonData.setSchools(schools);
            return null;
        });
    }

    /**
     * Funcion auxiliar para obtener cursos de colegio
     * @param schoolId
     */
    getCourses(schoolId: number) {
        this.adminServ.getSchoolGrades(schoolId).then(courses => {
            this.courses = courses;
            this.commonData.setGrades(this.courses);
        });
    }

    /**
     * Funcion auxiliar para actualizar los cursos al momento de cambiar el colegio
     * @param schoolId
     * @param setFilters
     */
    updateGrade(schoolId: any, setFilters?: any) {
        return this.adminServ.getSchoolGrades(schoolId);
    }

    /**
     * Funcion auxiliar para actualizar los estudiantes al momento de cambiar el curso
     * @param schoolId
     * @param courses
     */
    updateEstudiantes(schoolId: number[], courses: number[]) {
        return this.adminServ.getStudents(schoolId, courses);
    }
}
