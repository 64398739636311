import {Injectable} from '@angular/core';
import {SuperadminService} from "../services/superadmin.service";
import {CommonData} from "./common-data";

export interface InfoUser {
  active: boolean;
  urlPhoto: string;
  name: string;
  lastname: string;
  maritalStatus: string;
  gender: string;
  initialDate: string;
  birthDate: string;
  email: string;
  movileNumber: string;
  position: string;
  location: string;
  firstTime: boolean;
}

let USER =  {
  active: false,
  urlPhoto: 'assets/images/avatar-4.png',
  name: '',
  lastname: '',
  maritalStatus: '',
  gender: '',
  initialDate: '',
  birthDate: '',
  email: '',
  movileNumber: '',
  position: '',
  location: '',
  firstTime: false
};

@Injectable()
export class User  {

  getAll(): InfoUser {
    return USER;
  }
  Define( argument ) {
    USER.active = argument;
    // TODO es momentaneo, no tiene que ser remplazado
  }
  DefinirInfoUsuario( info) {
    USER.email = info.email;
    USER.name = info.first_name;
    USER.lastname = info.last_name;
    USER.gender = info.gender;
    USER.position = info.profile;
    if (info.url_avatar) {
      USER.urlPhoto = info.url_avatar;
    }
  }
  ResetearUser() {
     USER =  {
      active: false,
      urlPhoto: 'assets/images/avatar-4.png',
      name: '',
      lastname: '',
      maritalStatus: '',
      gender: '',
      initialDate: '',
      birthDate: '',
      email: '',
      movileNumber: '',
      position: '',
      location: '',
      firstTime: false
    };
  }
}
